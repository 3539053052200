import React from "react";
import {StaticQuery, graphql, Link} from "gatsby";
import {Helmet} from "react-helmet";
import {compagnyName, compagnyColorPrimary} from "../config/settings";

const allSignaturesQuery = graphql`
  query allSignatures {
    dataJson {
      signatures {
        slug
        name {
          firstname
          lastname
        }
      }
    }
  }
`;

const Title = {
    textAlign: "center",
    fontFamily: "Arial",
    textTransform: "uppercase",
};
const SignatureWrapper = {
    display: "flex",
    fontFamily: "Arial",
    justifyContent: "center",
};
const LinkStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vw",
    width: "10vw",
    backgroundColor: "rgba(0,0,0,0.1)",
    margin: "0 10px",
    padding: "20px",
    textDecoration: "none",
    textAlign: "center",
    color: "red",
    fontWeight: "bold",
    borderBottom: "solid 2px red",
};

const IndexPage = () => (
    <StaticQuery
        query={allSignaturesQuery}
        render={(data) => (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{compagnyName} - Signature mail</title>
                </Helmet>
                <h1 style={Title}>{compagnyName}</h1>
                <div style={SignatureWrapper}>
                    {data.dataJson.signatures.map((signature, index) => (
                        <div key={index}>
                            <Link
                                style={Object.assign({}, LinkStyle, {
                                    borderBottomColor: compagnyColorPrimary,
                                    color: compagnyColorPrimary,
                                    borderRadius: "5px",
                                })}
                                to={`/signatures/${signature.slug}`}
                            >
                                {signature.name.firstname} {signature.name.lastname}
                            </Link>
                        </div>
                    ))}
                </div>
            </>
        )}
    />
);

export default IndexPage;
